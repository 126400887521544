































































import {Component, Vue} from "vue-property-decorator";
import RequestW4Modal from "@/components/archive/w4/RequestW4Modal.vue";
import {namespace} from "vuex-class";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import FillableDocumentFilter from "@/dto/archive/FillableDocumentFilter";
import {FillableDocumentType} from "@/dto/archive/FillableDocumentType";
import DocumentService from "@/services/DocumentService";
import FillableDocumentDataDTO from "@/dto/archive/FillableDocumentDataDTO";
import Workspaces from "@/state/Workspaces";
import RouteNames from "@/router/RouteNames";

const AppModule = namespace("App");
const Auth = namespace("Auth");

@Component
export default class W4List extends Vue {

    private successful = false;

    private message = "";

    @AppModule.State
    private loading!: boolean;

    @AppModule.Action
    private startLoading!: () => void;

    @AppModule.Action
    private stopLoading!: () => void;

    private forms: Array<FillableDocumentDataDTO> = [];

    mounted() {
        this.loadForms();
    }

    showModal() {
        this.$modal.show(
            RequestW4Modal,
            {
                companyId: Workspaces.getCurrent.id,
                onSaved: () => this.loadForms()
            },
            {width: "50%", height: "auto", scrollable: true}
        );
    }

    loadForms(){
        this.startLoading();
        this.message = "";
        const filter = new FillableDocumentFilter();
        filter.requestingCompanyId = Workspaces.getCurrent.id;
      filter.documentType = FillableDocumentType.W_4;
        return DocumentService.getFillableByFilter(filter).then(
            success => this.forms = success.data.data,
            error => this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string
        ).then(() => this.stopLoading());;
    }

	private processEvent(form: FillableDocumentDataDTO) {
		if (form.publicKey != null) {
			this.$router.push(
				{
					name: RouteNames.PUBLIC_FORMS_W4,
					params: {
						publicId: `${form.publicKey}`
					}
				}
			)
		} else {
			Vue.prototype.$docUtils.download(form.signedFile!.id)
		}
	}
}
