










































import {Component, Prop, Vue} from "vue-property-decorator";
import CreateFillableDocumentRequestDTO from "@/dto/archive/CreateFillableDocumentRequestDTO";
import DocumentService from "@/services/DocumentService";
import {namespace} from "vuex-class";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import {FillableDocumentType} from "@/dto/archive/FillableDocumentType";
import EmploymentFilter from "@/dto/employee/EmploymentFilter";
import PersonDTO from "@/dto/person/PersonDTO";
import PortalSelect from "@/components/common/PortalSelect.vue";
import SelectOption from "@/components/common/SelectOption";
import {ifValid} from "@/utils/ComponentUtils";
import StaffService from "@/services/EmployeeService";
import EmployeeDto from "@/dto/payroll/EmployeeDto";
import {EmployeeFilter} from "@/dto/payroll/Filters";

const AppModule = namespace("App");

@Component({
	computed: {
		SelectOption() {
			return SelectOption
		}
	},
	components: {PortalSelect}
})
export default class RequestW8BENModal extends Vue {
    private successful = false;

    private message = "";

	private mock: any = null;

    @AppModule.State
    private loading!: boolean;

    @AppModule.Action
    private startLoading!: () => void

    @AppModule.Action
    private stopLoading!: () => void

    private request = new CreateFillableDocumentRequestDTO();


    @Prop()
    private companyId!: number;

    @Prop()
    private onSaved!: () => void;

    private selectedEmployee: PersonDTO = new PersonDTO();

    private employees: Array<EmployeeDto> = [];

    created() {
        this.request.requestingCompanyId = this.companyId;
        this.request.documentType = FillableDocumentType.W_4;
        this.loadEmployees();
    }

    loadEmployees() {
		this.startLoading();
		const filter = new EmployeeFilter()
		filter.pageSize = 100
		filter.employerId = this.$wss.getCurrent.id
		StaffService.findByFilter(filter).then(
			res => {
				this.employees = res.data.data;
				this.stopLoading()
			},
			err => this.errorHandle(err)
		)
    }

    errorHandle(error: any) {
        this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
        this.successful = false;
    }

    save() {
        ifValid(this, () => {
			this.message = "";
			let e: PersonDTO = this.employees.find(e => e.details.id = this.request.personId)!.details
			this.request.firstName = e.firstName;
			this.request.lastName = e.lastName;
			this.startLoading();
			DocumentService.register(this.request).then(
				() => {
					this.successful = true;
					this.onSaved();
					this.$modal.hideAll();
				},
				error => {this.errorHandle(error);}
			).then(() => this.stopLoading());
		})
    }
}
